import React from "react";
import { Nav, NavLink, NavIcon, Bars } from "./NavbarElements";

//<NavLink to="/">PussyCat</NavLink>
//      <NavIcon onClick={toggle}>
//      <p>About</p>
//    <Bars />
//        </NavIcon>

const Navbar = ({ toggle }) => {
  return (
    <>
      <Nav>
        <NavLink to="/">PussyCat</NavLink>
        <NavIcon>
          <p> .</p>
          <Bars />
        </NavIcon>
      </Nav>
    </>
  );
};

export default Navbar;
