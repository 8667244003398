import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
  FaTelegram
} from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  SocialIconLink
} from "./FooterElements";

//             <SocialIconLink href="/" target="_blank" aria-label="Youtube">
//<FaYoutube />
//</SocialIconLink>
//<SocialIconLink href="/" target="_blank" aria-label="Facebook">
//<FaFacebook />
//</SocialIconLink>
//<SocialIconLink href="/" target="_blank" aria-label="Instagram">
//<FaInstagram />
//</SocialIconLink>
//<SocialIconLink href="/" target="_blank" aria-label="Linkedin">
//              <FaLinkedin />
//</SocialIconLink>

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/">PussyCat</SocialLogo>
            <SocialIcons>
              <SocialIconLink
                href="https://twitter.com/PussyCatLife"
                target="_blank"
                aria-label="Twitter"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </SocialIconLink>

              <SocialIconLink
                href="https://t.me/PussyCatAnnoucements"
                target="_blank"
                aria-label="Telegram Annoucements"
                rel="noopener noreferrer"
              >
                <FaTelegram />
              </SocialIconLink>
              <SocialIconLink
                href="https://t.me/PussyCatCrypto"
                target="_blank"
                aria-label="Telegram Chat"
                rel="noopener noreferrer"
              >
                <FaTelegram />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
