import React, { useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import {
  HeroContainer,
  HeroContent,
  HeroItems,
  HeroH1,
  HeroP,
  HeroBtn
} from "./HeroElements";

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeroContainer>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <HeroContent>
        <HeroItems>
          <HeroH1>ETH HAS DOGS</HeroH1>
          <HeroP>PULSE HAS CATS</HeroP>
          <a
            href="https://v2-app.pulsex.com/swap?outputCurrency=0x733099917CbA30614e4b2ef1de8A551645665143"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HeroBtn>Buy $PUSSY</HeroBtn>
          </a>
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
