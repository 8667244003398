import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { GlobalStyle } from "./globalStyles";
import Hero from "./components/Hero";
import Products from "./components/Products";
import {
  productData,
  productDataTwo,
  productDataTheTeam
} from "./components/Products/data";
import Feature from "./components/Feature";
import Feature2 from "./components/Feature2";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Hero />
      <Products heading="PulseChain’s Ultimate Memecoin" data={productData} />
      <Feature />
      <Products
        heading="Interoperability for the Future of $PUSSY"
        data={productDataTwo}
      />
      <Products heading="The Team" data={productDataTheTeam} />

      <Footer />
    </Router>
  );
}

export default App;
