import React from "react";
import { FeatureContainer, FeatureButton } from "./FeatureElements";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { SidebarRoute, SideBtnWrap } from "../Sidebar/SidebarElements";

const Feature = () => {
  return (
    <FeatureContainer>
      <h1>The most parabolic memecoin on the most parabolic network</h1>
      <p>100% community run with $PUSSY as a governance token.</p>

      <a
        href="https://v2-app.pulsex.com/swap?outputCurrency=0x733099917CbA30614e4b2ef1de8A551645665143"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FeatureButton>Buy $PUSSY</FeatureButton>
      </a>
    </FeatureContainer>
  );
};

export default Feature;
