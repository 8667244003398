import React from "react";
import {
  ProductsContainer,
  ProductWrapper,
  ProductsHeading,
  ProductTitle,
  ProductCard,
  ProductImg,
  ProductInfo,
  ProductDesc,
  ProductPrice,
  ProductButton
} from "./ProductsElements";

const Products = ({ heading, data }) => {
  return (
    <ProductsContainer>
      <ProductsHeading>{heading}</ProductsHeading>
      <ProductWrapper>
        {data.map((product, index) => {
          if (product.item === 4 || product.item === 6) {
            return (
              <ProductCard key={index}>
                <ProductImg src={product.img} alt={product.alt} />
                <ProductInfo>
                  <ProductTitle>{product.name}</ProductTitle>
                  <ProductDesc>{product.desc}</ProductDesc>
                  <ProductPrice>{product.price}</ProductPrice>
                  <ProductButton>{product.button}</ProductButton>
                </ProductInfo>
              </ProductCard>
            );
          } else if (
            product.item === 7 ||
            product.item === 8 ||
            product.item === 9
          ) {
            return (
              <ProductCard key={index}>
                <ProductImg src={product.img} alt={product.alt} />
                <ProductInfo>
                  <ProductTitle>{product.name}</ProductTitle>
                  <ProductDesc>{product.desc}</ProductDesc>
                </ProductInfo>
              </ProductCard>
            );
          } else {
            return (
              <ProductCard key={index}>
                <ProductImg src={product.img} alt={product.alt} />
                <ProductInfo>
                  <ProductTitle>{product.name}</ProductTitle>
                  <ProductDesc>{product.desc}</ProductDesc>
                  <ProductPrice>{product.price}</ProductPrice>
                  <a
                    href="https://v2-app.pulsex.com/swap?outputCurrency=0x733099917CbA30614e4b2ef1de8A551645665143"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ProductButton>{product.button}</ProductButton>
                  </a>
                </ProductInfo>
              </ProductCard>
            );
          }
        })}
      </ProductWrapper>
    </ProductsContainer>
  );
};

export default Products;
