import product1 from "../../images/p1.jpg";
import product2 from "../../images/p2.jpg";
import product3 from "../../images/p3.jpg";
import p2_1 from "../../images/p2_1.jpg";
import p2_2 from "../../images/p2_2.jpg";
import p2_3 from "../../images/p2_3.jpg";
import fs from "../../images/FS.jpg";
import pulseLogoV2 from "../../images/pulseLogoV2.png";

export const productData = [
  {
    img: product1,
    alt: "1",
    name: "Supply",
    desc: "The initial circulating supply is the same as the total supply.",
    price: "420 Billion PUSSY",
    button: "Buy $PUSSY",
    item: 1
  },
  {
    img: product2,
    alt: "2",
    name: "Fair Launch Token",
    desc: "No Tokens are distributed to founders and core contributers.",
    price: "0 PUSSY",
    button: "Buy $PUSSY",
    item: 2
  },
  {
    img: product3,
    alt: "3",
    name: "Community Tokens",
    desc:
      "Tokens in the genesis wallet will be allocated by community vote towards marketing, partnerships, development, exchange listings, etc.",
    price: "63 Billion PUSSY",
    button: "Buy $PUSSY",
    item: 3
  }
];

export const productDataTwo = [
  {
    img: p2_2,
    alt: "1",
    name: "Built on BSC",
    desc:
      "$PUSSY was built on Binance Smart Chain and will soon be re-launched on PulseChain.  Existing holders will be airdropped 1:1, official release to be announced.",
    price: "",
    button: "Coming Soon",
    item: 4
  },
  {
    img: pulseLogoV2,
    alt: "2",
    name: "New on PulseChain",
    desc:
      "$PUSSY will be re-launching on PulseChain where the technology and community are better suited with PussyCat life.",
    price: "",
    button: "Buy $PUSSY",
    item: 5
  },
  {
    img: p2_1,
    alt: "3",
    name: "Bridged to ETH",
    desc:
      "$PUSSY will eventually be bridged to the Ethereum network as a synthetic token to allow for full interoperability with Ethereum based assets.",
    price: "",
    button: "Coming Soon",
    item: 6
  }
];

export const productDataTheTeam = [
  {
    img: fs,
    alt: "1",
    name: "0xB",
    desc:
      "0xB has been in the crypto space effectively since the start. 0xB cofounded very early businesses and projects in the crypto space as well as being heavily involved in various aspects of many other successful crypto projects.",
    price: "",
    button: "Coming Soon",
    item: 7
  },
  {
    img: fs,
    alt: "2",
    name: "0xF",
    desc:
      "0xF is a founder of large scale offline and online businesses. These commerce businesses are some of the biggest in their field. 0xF has been in the crypto space since 2016 and has applied knowledge of real-world business and marketing to PussyCatLife.",
    price: "",
    button: "Buy $PUSSY",
    item: 8
  },
  {
    img: fs,
    alt: "3",
    name: "0xC",
    desc:
      "0xC is a software and hardware engineer with over 35 years experience.  0XB became proficient in block-chain programming in 2017, specifically in Solidity and later in Go-Ethereum (Geth).",
    price: "",
    button: "Coming Soon",
    item: 9
  }
];
